// Generated by Framer (808c984)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import RotatingGradient from "https://framerusercontent.com/modules/e6rpMH52NShMxQ5bURkF/dBNozjg3QG0W8UTzSRSb/ShinyBorder.js";
import { withscaleOnHover } from "https://framerusercontent.com/modules/jc8m3L482KazUkykzemn/2PUSl5qy3aHZFO8qpQXd/UpScale.js";
const RotatingGradientFonts = getFonts(RotatingGradient);
const MotionAWithscaleOnHover = withscaleOnHover(motion.a);

const cycleOrder = ["OTthjrJ4O"];

const serializationHash = "framer-M823R"

const variantClassNames = {OTthjrJ4O: "framer-v-1y7enfb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, Gblgr_u_X: link ?? props.Gblgr_u_X, qg6Pp43oj: title ?? props.qg6Pp43oj ?? "Get The Effect"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Gblgr_u_X, qg6Pp43oj, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "OTthjrJ4O", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={Gblgr_u_X} openInNewTab={false}><MotionAWithscaleOnHover {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1y7enfb", className, classNames)} framer-187g1va`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"OTthjrJ4O"} ref={ref ?? ref1} style={{backgroundColor: "rgb(46, 46, 46)", borderBottomLeftRadius: 90, borderBottomRightRadius: 90, borderTopLeftRadius: 90, borderTopRightRadius: 90, ...style}}><ComponentViewportProvider ><motion.div className={"framer-1b1ti8p-container"} layoutDependency={layoutDependency} layoutId={"ib2BPNtZq-container"}><RotatingGradient gradientSize={306} height={"100%"} id={"ib2BPNtZq"} layoutId={"ib2BPNtZq"} rotationSpeed={3} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider><motion.div className={"framer-ykszy1"} layoutDependency={layoutDependency} layoutId={"HTQ277NFw"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 80, borderBottomRightRadius: 80, borderTopLeftRadius: 80, borderTopRightRadius: 80}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7RE0gU2Fucy1yZWd1bGFy", "--framer-font-family": "\"DM Sans\", \"DM Sans Placeholder\", sans-serif", "--framer-font-size": "24px", "--framer-text-color": "var(--extracted-1of0zx5, rgb(255, 255, 255))"}}>Get The Effect</motion.h2></React.Fragment>} className={"framer-ar6mv3"} fonts={["GF;DM Sans-regular"]} layoutDependency={layoutDependency} layoutId={"F3pqpFUcj"} style={{"--extracted-1of0zx5": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={qg6Pp43oj} verticalAlignment={"top"} withExternalLayout/></motion.div></MotionAWithscaleOnHover></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-M823R.framer-187g1va, .framer-M823R .framer-187g1va { display: block; }", ".framer-M823R.framer-1y7enfb { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 2px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-M823R .framer-1b1ti8p-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-M823R .framer-ykszy1 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 24px 32px 24px 32px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-M823R .framer-ar6mv3 { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-M823R.framer-1y7enfb, .framer-M823R .framer-ykszy1 { gap: 0px; } .framer-M823R.framer-1y7enfb > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-M823R.framer-1y7enfb > :first-child { margin-left: 0px; } .framer-M823R.framer-1y7enfb > :last-child { margin-right: 0px; } .framer-M823R .framer-ykszy1 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-M823R .framer-ykszy1 > :first-child { margin-top: 0px; } .framer-M823R .framer-ykszy1 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 81
 * @framerIntrinsicWidth 228
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Gblgr_u_X":"link","qg6Pp43oj":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpuKI2CPwG: React.ComponentType<Props> = withCSS(Component, css, "framer-M823R") as typeof Component;
export default FramerpuKI2CPwG;

FramerpuKI2CPwG.displayName = "ShinyButton Copy";

FramerpuKI2CPwG.defaultProps = {height: 81, width: 228};

addPropertyControls(FramerpuKI2CPwG, {Gblgr_u_X: {title: "Link", type: ControlType.Link}, qg6Pp43oj: {defaultValue: "Get The Effect", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerpuKI2CPwG, [{explicitInter: true, fonts: [{family: "DM Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhS2f3ZGMZpg.woff2", weight: "400"}]}, ...RotatingGradientFonts], {supportsExplicitInterCodegen: true})